@mixin kyc-data-entry-rtl {
  .kyc {
    &__form {
      &_date_input_wrap {
        .mat-datepicker-toggle {
          right: auto !important;
          left: 0 !important;
        }
      }
    }
  }

  .status-kyc {
    &__title-btn {
      padding-right: 0;
      padding-left: 40px;
    }
  }

  .img-upload-list {
    &__item {
      padding-left: 0;
      padding-right: 12px;
      span {
        position: absolute;
        right: 0;
      }
    }
  }
}
