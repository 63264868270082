@mixin dropdown-list-theme() {
  .mat-menu-panel {
    min-width: 200px;
    box-shadow: none;
    background: transparent !important;
  }

  .mat-menu-content {
    min-width: 100px !important;
    background-color: #eef2f7;
    box-shadow: none;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    border-radius: 20px !important;
    color: #000000;
  }

  .mat-menu-content:not(:empty) {
    padding: 0 !important;
    border: 1px solid #e8eff4;
  }

  .mat-menu-item {
    height: auto !important;
    font-size: 1.4rem;
    text-decoration: none !important;
    margin-bottom: 0 !important;
    &:not(:last-child) {
      border-bottom: 1px solid #e8eff4;
    }
    &:hover {
      color: #7460ee;
      background-color: #ffffff0f !important;
    }

    .filter__dropdown {
      .mat-menu-item {
        font-size: 14px;
      }
      .mat-option-text {
        font-size: 14px;
      }
    }
  }

  .mat-menu-item:hover {
    background: transparent !important;
  }

  .game-station {
    .mat-menu-content {
      background-color: #1e1b34;
      box-shadow: 0 4px 20px rgba(0, 0, 0, 0.08);
      border-radius: 10px !important;
      border: solid 1px #4b4673;
      overflow: hidden;
    }

    .mat-menu-item {
      &:not(:last-child) {
        border-bottom: 1px solid #1e1b34;
      }
      &:hover {
        background: #110e23 !important;
      }
    }

    .item {
      border-bottom: none !important;
      color: #ffffff;
      margin-top: 0;
      display: flex !important;
      justify-content: flex-start;
      align-items: center;
      height: 46px !important;
      padding: 0 !important;
      &__icon {
        height: 18px;
        min-width: 18px;
        font-size: 18px;
        width: 18px;
        margin-right: 6px !important;
        margin-left: 18px;
        color: #666473 !important;
      }
      &__label {
        color: #ffffff;
        font-family: GothamMedium;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 18px;
      }
      &:disabled {
        opacity: 0.3;
      }
    }

    .item-active {
      background: #110e23;
    }

    .mat-menu-item[disabled] {
      opacity: 0.3 !important;
    }
  }

  .trial {
    .mat-menu-content {
      min-width: 100px !important;
      border-radius: 10px !important;
      padding: 10px 0 10px 0 !important;
    }
  }

  .kaishi {
    .mat-menu-content {
      min-width: 100px !important;
      background: linear-gradient(180deg, #35405b 0%, #222c45 100%);
      box-shadow: 0 0 0 2px #1a2238, -5px -5px 10px rgba(255, 255, 255, 0.1), 5px 10px 10px rgba(1, 7, 19, 0.2);
      -webkit-border-radius: 20px;
      -moz-border-radius: 20px;
      color: #2bd4b5;
      border-radius: 20px !important;
      border: none;
      padding: 10px 0 10px 0 !important;
    }
    .mat-menu-panel {
      border-radius: 20px !important;
    }

    .mat-menu-item {
      &:not(:last-child) {
        border-bottom: 1px solid #1e1b34;
      }
    }

    .item {
      width: 182px !important;
      border-bottom: none !important;
      color: #ffffff;
      margin-top: 0;
      display: flex !important;
      justify-content: flex-start;
      align-items: center;
      height: 46px !important;
      padding: 0 !important;

      &__icon {
        height: 18px;
        min-width: 18px;
        font-size: 18px;
        width: 18px;
        margin-right: 6px;
        margin-left: 18px;
        color: #666473 !important;
      }
      &__label {
        color: #ffffff;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 18px;
      }
      &:disabled {
        opacity: 0.3;
      }
    }

    .item-active {
      background: #110e23;
    }

    .mat-menu-item[disabled] {
      opacity: 0.3 !important;
    }
  }

  .kingaru {
    .mat-menu-content {
      min-width: 150px !important;
      background-color: #ffffff;
      border-radius: 5px !important;
      border: solid 1px #e8eff4;
      padding: 0 !important;
    }

    .item {
      color: #000000;
      margin-top: 0;
      display: flex !important;
      justify-content: flex-start;
      align-items: center;
      height: 46px !important;
      padding: 0 15px !important;

      &__icon {
        margin-right: 10px;
        height: 18px;
        min-width: 18px;
        width: 18px;
        color: #666473 !important;
      }
      &__label {
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 18px;
      }
      &:disabled {
        opacity: 0.3;
      }
    }

    .item-active {
      color: #7460ee;
    }

    .mat-menu-item[disabled] {
      opacity: 0.3 !important;
    }
  }
}
