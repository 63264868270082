@mixin left-navigation {
  .menu-position-left_side_bar:not(.admin-page) {
    .side-navigation-wrapper {
      display: block;
    }

    &[dir='ltr'] {
      .mat-drawer-content {
        margin-left: 230px;
      }
    }

    &[dir='rtl'] {
      .mat-drawer-content {
        margin-left: 0 !important;
        margin-right: 230px !important;
      }
    }
  }
}
