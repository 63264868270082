@mixin user-theme {
  background-color: #f8f9fa;
  .mat-drawer-container {
    overflow: inherit;
    overflow: hidden !important;
  }
  .mat-drawer-content {
    position: static !important;
  }

  .header {
    top: 0;
    left: 0;
    width: 100%;
    z-index: 20;
    &__inner {
      margin: auto;
    }
  }

  .header__btn,
  .mob_lang_btn {
    display: none;
  }

  .kaishi,
  .trial,
  .game-station {
    .change-dir {
      display: none;
    }
  }

  @media (max-width: 960px) {
    app-toolbar {
      width: calc(100% - 50px);
      overflow: visible !important;
    }
    .header,
    .header {
      &__inner {
        padding: 0;
      }
      &__btn,
      .mob_lang_btn {
        display: block;
      }
      .mob_lang_btn {
        flex-shrink: 0;
      }
    }
    .header__left-part,
    .header__left-part {
      width: 100%;
      overflow: visible !important;
    }
    .mat-nav-list {
      padding: 0;
    }

    .kingaru,
    .trial,
    .game-station {
      .mat-sidenav-container {
        .mat-nav-list a,
        .mat-nav-list button {
          text-align: center;
          padding: 0;
          border-bottom: 1px solid #e8eff40f;
          height: 50px;
          margin-bottom: 0;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }

    .kingaru,
    .trial {
      .mat-sidenav-container {
        .main-wrap__aside {
          display: block !important;
          max-width: 100% !important;
        }
      }
    }

    .game-station {
      .mat-sidenav-container {
        .mat-nav-list a,
        .mat-nav-list button {
          border-bottom: 1px solid #1f1f1f;
        }
        .main-wrap__aside {
          display: block !important;
          max-width: 100% !important;
          background-color: #17142f !important;
          overflow-y: auto;
          max-height: 100%;
        }
      }
    }

    .kaishi {
      .header__btn {
        color: #ffffff;
      }
    }
  }
}
