@mixin user-detail-rtl() {
  .user-detail {
    &__img {
      margin-right: 0;
      margin-left: 15px;
    }
    &__name {
      padding-right: 0;
      padding-left: 15px;
    }
  }

  app-user-change-status {
    border-left: 0;
    border-right: 1px solid #e8eff4;
    padding-left: 0;
    padding-right: 17px;
  }

  @media (max-width: 1199px) {
    app-user-change-status {
      border-right: none;
      padding-right: 0;
    }
  }
}
