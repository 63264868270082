@import 'general-theme';

html,
body {
  width: 100%;
  height: 100%;
}

html {
  font-size: 10px;
}

body {
  font-family: 'Rubik', sans-serif;
  font-size: 1.6rem;
}

h3 {
  font-size: 1.4rem;
  line-height: 1.1875;
}

button {
  cursor: pointer;
  &:hover,
  &:focus {
    outline: none;
  }
  &:disabled {
    cursor: not-allowed !important;
  }
}

svg {
  max-width: 100%;
  max-height: 100%;
}

.spinner-bg {
  position: absolute;
  background-color: white;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.spinner-bg-main {
  position: fixed;
  z-index: 1000000000;
}

.spinner-bg-transparent {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
}

.hiddenOverflow {
  overflow: hidden;
}

.mat-progress-spinner {
  width: 60px !important;
  height: 60px !important;
  circle {
    stroke: #7460ee;
  }
  svg {
    width: 60px !important;
    height: 60px !important;
  }
}

.mat-simple-snackbar {
  justify-content: center !important;
}

.dashboard-head {
  min-height: 60px;
  padding: 0 17px;
  margin-bottom: 15px;
  margin-right: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  &__headline {
    font-size: 2rem;
    font-weight: 500;
    margin-right: 10px;
  }

  &__row {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &__btn {
    border: none;
    box-sizing: border-box;
    padding: 0 15px;
    border-radius: 20px;
    min-width: 74px;
    height: 28px;
    font-size: 11px;
    line-height: 13px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #735fed;
    color: #ffffff;
    font-weight: normal;
    white-space: nowrap;

    img {
      width: 18px;
      height: 18px;
    }

    &:not(:last-child) {
      margin-right: 10px;
    }
  }
}

.block-line {
  width: 100%;
  height: 1px;
  background-color: #e8eff4;
}
.mat-card {
  padding: 0 !important;
  box-shadow: none !important;
  margin-bottom: 10px;
  &-header {
    padding: 20px 17px;
    line-height: normal;
    &-text {
      display: none;
    }
  }
  &-content {
    position: relative;
    padding: 20px 17px;
  }
}
.important-field {
  color: red;
}
.error {
  display: block;
  width: 100%;
  font-size: 14px;
  line-height: 1.16;
  color: #ff0027;
  margin: 5px 0 10px;
}
.top-navigation-wrapper {
  display: none;
}

.note-editable {
  min-height: 600px;
}

.modal-opened {
  position: fixed;
  top: 0;
  left: 0;
  overflow: hidden;
}

.gr_ver_2 {
  background: transparent !important;
}

.card-block {
  &__list {
    text-align: left;
  }
  &__header-text {
    font-size: 12px;
    line-height: 1.3;
    color: #8796a6;
    margin-bottom: 5px;
  }
  &__data-text {
    font-weight: 500;
    font-size: 14px;
    margin-bottom: 15px;
  }
  &__btn-wrapper {
    text-align: center;
  }
  &__small-btn {
    border-radius: 20px;
    font-size: 12px;
    font-weight: normal;
    line-height: 30px;
    min-width: 110px;
  }
}

.disable-div {
  pointer-events: none;
  opacity: 0.7;
}

.card-box-border {
  border: 1px solid #e8eff4;
  border-radius: 3px;
}

.firstActiveItem,
.lastActiveItem {
  pointer-events: none;
  opacity: 0.4;
}

.atlwdg-popup {
  width: 95% !important;
}

.note-modal-backdrop {
  display: none !important;
}
.note-modal-footer {
  padding: 0 !important;
  margin: 0 30px 10px;
}

.note-modal {
  position: absolute !important;
  top: 50px !important;
}

@media (max-width: 767px) {
  .modal-user-block {
    overflow: auto;
    max-width: 100% !important;
    height: 100%;
    width: 100% !important;
    .mat-dialog-container {
      padding: 0;
    }
  }
}
