.mat-tooltip {
  font-size: 12px;
  line-height: 1.16;
  color: #f8f9fa !important;
  background: #2c3032;
  border-radius: 5px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.15);
  padding: 10px 13px !important;
}

.game-station {
  .custom-snackbar {
    border: 1px solid #e6298b;
    background: #17142f;
    border-radius: 10px;
    color: #14d5dd;
  }

  .custom-theme-tooltip {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 15px;
    background: #110e23;
    border: 1px solid #4b4673;
    border-radius: 10px !important;
    color: #878690 !important;
    text-align: left;
  }
}
.kaishi {
  .custom-snackbar {
    box-shadow: 0 0 0 2px #1a2238, -5px -5px 10px rgba(255, 255, 255, 0.1), 5px 10px 10px rgba(1, 7, 19, 0.2);
    background: #181f35;
    border-radius: 20px;
    color: #ffffff;
  }
  .custom-theme-tooltip {
    background: linear-gradient(180deg, #35405b 0%, #222c45 100%);
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    color: #ffffff;
    border: solid 3px #10172a;
    box-shadow: 0 0 0 #1a2238, -5px -5px 10px rgba(255, 255, 255, 0.1), 5px 10px 10px rgba(1, 7, 19, 0.2);
    border-radius: 20px;
    //min-width: 300px;
    padding: 15px 30px !important;
    overflow: visible;

    &::before {
      position: absolute;
      content: '';
      display: inline-block;
      background-color: #10172a;
      clip-path: polygon(35% 0%, 0 65%, 70% 65%);
      width: 30px;
      height: 22px;
      right: 40%;
      top: -15px;
    }
  }

  //.mat-tooltip-panel{
  //  margin-left: 110px!important;
  //}
}

.trial {
  .custom-snackbar {
    background: #18586e;
    border-radius: 10px;
    color: #ffffff;
  }
}
