@mixin user-info-rtl {
  .profile-menu_wrap {
    left: 0;
    right: auto;
  }

  .profile-menu {
    &:before {
      right: 105px;
    }
  }
}
