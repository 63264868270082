@use '~@angular/material' as mat;
@import '~@angular/material/theming';

$my-dark-theme-background: (
  app-bar: map-get(mat.$blue-palette, 900),
  background: #141719,
  hover: rgba(white, 0.04),
  card: #1a1f21,
  dialog: #1a1f21,
  border: #313638,
  disabled-button: $white-12-opacity,
  raised-button: map-get(mat.$grey-palette, 800),
  focused-button: $white-6-opacity,
  selected-button: #141719,
  selected-disabled-button: map_get(mat.$grey-palette, 800),
  disabled-button-toggle: black,
  unselected-chip: map_get(mat.$grey-palette, 700),
  disabled-list-option: black,
  error-network-card__background: #3f3f3f,
);

$my-dark-theme-foreground: (
  base: #fff,
  divider: $white-12-opacity,
  dividers: $white-12-opacity,
  disabled: rgba(white, 0.3),
  disabled-button: rgba(white, 0.3),
  disabled-text: rgba(white, 0.3),
  hint-text: rgba(white, 0.3),
  secondary-text: rgba(white, 0.7),
  icon: #fff,
  icons: #fff,
  text: #fff,
  slider-min: #fff,
  slider-off: rgba(#fff, 0.3),
  slider-off-active: rgba(white, 0.3),
);

@function create-wonky-theme($primary, $accent, $warn: mat.define-palette(mat.$red-palette)) {
  @return (
    primary: $primary,
    accent: $accent,
    warn: $warn,
    is-dark: false,
    foreground: $my-dark-theme-foreground,
    background: $my-dark-theme-background
  );
}

.profile-menu {
  background-color: map-get($my-dark-theme-background, border) !important;
  &:before {
    border-color: map-get($my-dark-theme-background, border) !important;
  }
  a {
    color: map-get($my-dark-theme-foreground, base);
    border-color: map-get($my-dark-theme-background, card) !important;
  }
}

.mat-sidenav-container .mat-nav-list a,
.mat-sidenav-container .mat-nav-list button {
  border-color: rgba(255, 255, 255, 0.1);
}

.mat-sidenav-container .mat-nav-list mat-icon svg path {
  fill: #ffffff !important;
}

.mat-option {
  border-color: rgba(255, 255, 255, 0.1);
}

.mat-option-text {
  color: map-get($my-dark-theme-foreground, base);
}

.mat-select-arrow {
  border-color: map-get($my-dark-theme-foreground, base);
}

.mat-select-arrow {
  &:before,
  &:after {
    background-color: map-get($my-dark-theme-foreground, base);
  }
}

.mat-select-value-text {
  color: inherit;
}

.mat-expansion-indicator::after {
  border-color: map-get($my-dark-theme-foreground, base);
}

.convertation-block__item,
input,
textarea {
  background-color: map-get($my-dark-theme-background, background);
  border-color: map-get($my-dark-theme-background, border) !important;
  color: map-get($my-dark-theme-foreground, base) !important;
}

mat-select,
.mat-select-trigger {
  border-color: map-get($my-dark-theme-background, border) !important;
}

.mat-header-row {
  background-color: map-get($my-dark-theme-background, background) !important;
}

.expansion-table-wrap {
  padding: 10px;
}

.expansion-table-wrap .mat-table {
  background-color: map-get($my-dark-theme-background, background);
}

.expansion-table-wrap .mat-paginator .mat-paginator-container {
  padding: 0 10px;
}

.mat-header-cell,
.mat-cell {
  border: 0;
}

.mat-row:nth-child(even) {
  background-color: map-get($my-dark-theme-background, background) !important;
}

.mat-mini-fab {
  background-color: map-get($my-dark-theme-background, hover) !important;
}

.expansion-table-wrap,
.table-wrap {
  border: 1px solid map-get($my-dark-theme-background, border) !important;
}
.mat-header-row,
.mat-row {
  border-bottom: map-get($my-dark-theme-background, border) !important;
}

.mat-sort-header-sorted {
  color: map-get($my-dark-theme-foreground, base);
  .mat-sort-header-arrow {
    color: map-get($my-dark-theme-foreground, base);
  }
}

.sign-in-btn {
  background-color: map-get($my-dark-theme-foreground, base) !important;
  color: map-get($my-dark-theme-background, background) !important;
}
.registration-btn {
  background-color: #262f34 !important;
}

.ico-logo__text {
  color: map-get($my-dark-theme-foreground, base) !important;
}

.user-block__icon-wrapper {
  background-color: #141719 !important;
  i {
    color: map-get($my-dark-theme-foreground, base) !important;
  }
}

.popup_menu__lang_btn__line,
.block-line {
  background-color: rgba(255, 255, 255, 0.1);
}

.mat-drawer-backdrop.mat-drawer-shown,
.kyc-verification {
  background-color: rgba(0, 0, 0, 0.7) !important;
}

.kyc__file-select_input,
.file-select__status,
.kyc__block_title_gray,
.kyc__form_radio_wrap,
.user-form__radio-wrp {
  background-color: map-get($my-dark-theme-background, background) !important;
}

.mat-expansion-panel {
  border-color: rgba(255, 255, 255, 0.1) !important;
  .mat-expansion-panel-header.mat-expanded {
    box-shadow: none;
    background-color: map-get($my-dark-theme-background, card) !important;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1) !important;
  }
}
.mat-card-content {
  background-color: map-get($my-dark-theme-background, background) !important;
}

.popup_menu__options_list,
.popup_menu__options_list__item:not(:last-child) {
  border-color: map-get($my-dark-theme-background, dialog);
}

.logout-menu,
.popup_menu__options_list__link {
  background-color: map-get($my-dark-theme-background, background) !important;
}

.logout-menu:before,
.popup_menu__options_list:before {
  border-color: map-get($my-dark-theme-background, background) !important;
}

.logout-menu__link,
.popup-menu {
  color: map-get($my-dark-theme-foreground, base) !important;
}

#fiat-wrp {
  background-color: map-get($my-dark-theme-background, background) !important;
}

.spinner-bg {
  background-color: map-get($my-dark-theme-background, background) !important;
}

.modal__spinner-bg {
  background-color: map-get($my-dark-theme-background, card);
}

.account-status {
  background-color: rgba(0, 0, 0, 0.7);
}

.dashboard-head__headline {
  color: map-get($my-dark-theme-foreground, base);
}

$custom-theme-primary: mat.define-palette(mat.$yellow-palette);
$custom-theme-accent: mat.define-palette(mat.$blue-palette);
$custom-theme-warn: mat.define-palette(mat.$red-palette);

$custom-theme: create-wonky-theme($custom-theme-primary, $custom-theme-accent, $custom-theme-warn);

.kyc-notice {
  background-color: map-get($my-dark-theme-background, background) !important;
}

.button-block__kyc-fee {
  background-color: map-get($my-dark-theme-background, background) !important;
}
.selected-row {
  background-color: map-get($my-dark-theme-background, background) !important;
}

.user_settings__logout-btn {
  background-color: map-get($my-dark-theme-background, background) !important;
  color: map-get($my-dark-theme-foreground, base) !important;
}

.user_settings__profile-card__email-section__form__input {
  background-color: map-get($my-dark-theme-background, background) !important;
}

.modal__google-auth-text-block {
  background-color: map-get($my-dark-theme-background, background) !important;
  border-top: solid 1px map-get($my-dark-theme-background, background) !important;
  border-bottom: solid 1px map-get($my-dark-theme-background, background) !important;
}
.transparent-btn {
  background: #313537 !important;
  color: #8796a6 !important;
  border: none !important;
}

.chain-section__item {
  background: transparent !important;
}
.apr-stake-block-wrap__section__item-bg {
  background-color: map-get($my-dark-theme-background, card) !important;
}
.mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: #fff !important;
}

.amount-text-box {
  background-color: map-get($my-dark-theme-background, background);
}
.total-balances-box {
  background-color: map-get($my-dark-theme-background, card);
}

.main-wrap {
  background-color: map-get($my-dark-theme-background, card) !important;

  &__aside {
    background-color: map-get($my-dark-theme-background, card) !important;
  }
}
.dashboard-head__headline,
.user_settings__profile-card__section-header,
.user-block__info-wrapper__name,
.user-block__info-icon,
.switch-btn-container__btn,
.mat-paginator-range-label {
  color: map-get($my-dark-theme-foreground, base);
}

.header {
  background-color: map-get($my-dark-theme-background, card) !important;
  box-shadow: inset 0px -1px 0px #313638 !important;
}

svg-icon svg path {
  fill: none;
  stroke: white;
}

.backdrop-bg {
  .error-network-card {
    background-color: map-get($my-dark-theme-background, error-network-card__background) !important;
    .network-error-header__icon-network {
      background-color: #121c26c2 !important;
    }
    .network-error-header__title {
      color: map-get($my-dark-theme-foreground, base) !important;
    }

    .network-error-notice__text {
      b {
        color: map-get($my-dark-theme-foreground, base) !important;
      }
    }
    .network-error-notice__btn {
      color: map-get($my-dark-theme-foreground, base) !important;
    }
  }
}
.save-changes-block {
  background-color: map-get($my-dark-theme-background, background) !important;
}

@media (max-width: 960px) {
  .table-wrap .mobile-table .mat-row-multi-table {
    border: 1px solid #2c3032 !important;
  }
  .table-wrap {
    border: none !important;
  }

  .color-mobile {
    color: map-get($my-dark-theme-foreground, base) !important;
  }

  .mobile-design-color {
    background-color: rgba(0, 0, 0, 0.7) !important;
  }
  .mobile-design-width {
    border-right: none !important;
  }
}
