@mixin kyc-notice-rtl {
  .kyc-notice {
    left: 10px;
    right: auto;
    div.icon-close {
      left: 10px;
      right: auto;
    }
  }
}
