@mixin slide-toggle-theme() {
  .mat-slide-toggle-bar {
    width: 36px;
    height: 19px;
    border-radius: 50px;
    background-color: #8796a6;
  }
  .mat-slide-toggle-thumb-container {
    top: 3px;
    left: 4px;
    width: 13px;
    height: 13px;
  }

  .mat-slide-toggle-thumb {
    width: 13px;
    height: 13px;
  }
}
