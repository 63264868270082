@mixin countriea-statistic-theme() {
  .mapinfo {
    min-width: 120px;
    font-size: 1.3rem;
    line-height: 1.8461;
    background-color: #25292d;
    color: #fff;
    border-radius: 5px;
    padding: 3px 10px;
    word-break: break-word;
  }

  @media (max-width: 1199px) {
    .mapinfo {
      min-width: 100px;
      font-size: 0.9rem;
      padding: 3px;
    }
  }

  @media (max-width: 959px) {
    .datamaps-hoverover {
      position: fixed !important;
      width: 100%;
      bottom: 0 !important;
      left: 0 !important;
      top: auto !important;
    }

    .mapinfo {
      font-size: 1.3rem;
      border-radius: 0;
      padding: 5px 15px;
    }
  }
}
