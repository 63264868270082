@import 'input.theme';
.filter {
  display: flex;
  &__input,
  &__select,
  &__textarea {
    width: 100%;
    height: 40px;
    font-size: 1.4rem;
    border: 1px solid #e8eff4;
    border-radius: 5px;
    box-shadow: none;
    padding: 0 17px;
    transition: 0.25s;
    &:focus {
      border-color: #7460ee;
      outline: none;
    }
  }
  &__input {
    max-width: 460px;
    &::placeholder {
      color: #8796a6;
    }
  }
  &__select {
    min-width: 215px;
    line-height: 40px;
  }
  &__select-mini {
    min-width: 135px;
  }
  &__select-disabled {
    pointer-events: none;

    .mat-select-arrow {
      opacity: 0;
    }
  }
  &__label {
    font-size: 1.2rem;
    color: #8796a6;
    padding-top: 14px;
    margin-left: 15px;
    margin-right: 15px;
  }
}

.mat-select-value {
  font-size: 1.4rem;
  font-weight: 400;
  color: #8796a6;
}

.mat-option {
  font-size: 1.4rem;
  border-bottom: 1px solid #e8eff4;
}

.mat-selected {
  background-color: initial !important;
  color: inherit;
}

.mat-select-panel {
  & .mat-option {
    line-height: 40px !important;
    height: 40px !important;
  }
}

.mat-select-value-text {
  color: initial;
}

.mat-select-placeholder {
  color: #8796a6;
}

.mat-pseudo-checkbox {
  border-width: 1px !important;
  border-color: #e8eff4 !important;
  &-checked {
    background-color: transparent;
    color: initial;
    &:after {
      top: 5px !important;
      left: 3px !important;
      color: initial;
      border-bottom: 2px solid #000 !important;
      border-left: 2px solid #000 !important;
    }
  }
}

.mat-option-text {
  font-size: 1.3rem;
  color: initial;
}

.input-field {
  position: relative;
  &__btn {
    position: absolute;
    top: 11px;
    right: 7px;
    background-color: #fff;
    color: initial;

    &:hover {
      color: #7460ee;
    }
  }
  &__icon {
    width: 18px !important;
    height: 18px !important;
    font-size: 1.8rem;
  }
}

.input-text-item {
  display: flex;
  align-items: baseline;
  margin-bottom: 14px;

  &__label {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    min-width: 100px;
  }

  &__item-text {
    position: relative;
    width: 100%;

    &__input {
      height: 40px;
      border: 1px solid #e8eff4;
      padding: 0 20px;
      border-radius: 5px;
      width: 100%;
      min-width: 100%;
      &::placeholder {
        color: #8796a6 !important;
        font-weight: normal;
        font-size: 14px;
      }
      &:hover,
      &:focus {
        outline: none;
      }
    }
    &__text {
      position: absolute;
      right: 20px;
      top: 14px;
      color: #8796a6 !important;
      font-weight: normal;
      font-size: 14px;
      padding-left: 20px;
    }
  }
}

.input-search {
  position: relative;
  height: 40px;
  margin-bottom: 30px;
  i {
    position: absolute;
    color: #8796a6;
    line-height: 40px;
    padding: 0 17px;
  }
  input {
    height: 40px;
    padding-left: 50px;
  }
}

.filter__dropdown.filter__dropdown_header {
  .mat-option-text {
    font-size: 14px;
  }
}

.filter__dropdown.filter__dropdown_tokens {
  .mat-option-text {
    font-size: 16px;
  }

  .mat-option {
    padding: 0;
    justify-content: center;
    align-items: center;
    display: flex;
    text-align: center;

    .mat-option-text {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

@media (max-width: 960px) {
  .mat-select-panel.filter__dropdown.filter__dropdown_aside {
    .mat-option {
      padding: 25px 16px;
    }
  }
}

input.mat-input-element {
  margin-top: initial;
}

@media (max-width: 767px) {
  .filter {
    flex-direction: column;
    &__input,
    &__select {
      max-width: 100%;
      margin-left: 0;
    }
    &__label {
      text-align: initial;
      padding-top: 0;
      margin-left: initial;
      margin-bottom: 20px;
    }
  }
}
