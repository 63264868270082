@import 'animations';
@import 'font';

$selector-base: 'loading-placeholder';

$placeholder-loading__color: rgba(0, 0, 0, 0.1) !default; // TODO: create separate variables for colors
$placeholder-loading__border-radius: 4px !default; // TODO: create separate variables for base radius's

.#{$selector-base} {
  background-color: $placeholder-loading__color;
  color: $placeholder-loading__color;
  border-radius: $placeholder-loading__border-radius;
  display: inline-block;
  height: $font-size-base;
  vertical-align: middle;
  width: 100%;
  position: relative;
  overflow: hidden;

  &::before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    height: 100%;
    width: 100%;
    background: linear-gradient(to right, transparent 0%, $placeholder-loading__color 50%, transparent 100%);
    animation: placeHolderAnimation 1.5s linear infinite;
  }
}

@for $i from 1 through 20 {
  .#{$selector-base}--#{$i * 5} {
    width: $i * 5%;
  }
}

@for $i from 1 through 10 {
  .#{$selector-base}--#{$i}rem {
    width: #{$i}rem;
  }
}

@each $size, $font-size in $font-sizes {
  .#{$selector-base}--#{$size} {
    height: $font-size;
  }
}
