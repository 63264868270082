@mixin invoices-list-rtl() {
  .input-search i {
    left: 17px;
  }

  .pending-element-detail {
    text-align: right;
  }

  .pending-block__btn {
    &:not(:last-child) {
      margin-right: 10px;
      margin-left: 30px;
    }
  }
}
