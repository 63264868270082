@mixin kyc-status-badge-rtl() {
  .kyc-status {
    &__text {
      &_padding {
        padding-right: 0;
        padding-left: 30px;
      }
    }

    &__btn {
      right: auto;
      left: 17px;
    }
  }
}
