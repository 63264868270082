@use '~@angular/material' as mat;
/* Font style */
$basic-typography: mat.define-typography-config(
  $font-family: 'Rubik',
);

@include mat.core($basic-typography);
/* You can add global styles to this file, and also import other style files */
@import 'assets/reset';
@import 'assets/global-loader';
@import 'assets/general';
@import 'assets/input';
@import '~flexboxgrid/dist/flexboxgrid';
@import '~@angular/material/prebuilt-themes/indigo-pink.css';
@import '~@angular/material/theming';
@import 'themes/default-theme.scss';
@import '~flag-icon-css/css/flag-icon.min.css';

/*@import 'reset.scss-theme';*/
/*@import 'app/pages/admin-dashboard/components/investors-total-stats-item/investors-total-settings-contributions-item.scss-theme';*/

@font-face {
  font-family: GothamBold;
  src: url('./assets/fonts/Gotham-Bold.otf') format('opentype');
}
@font-face {
  font-family: GothamLight;
  src: url('./assets/fonts/Gotham-Light.otf') format('opentype');
}
@font-face {
  font-family: GothamMedium;
  src: url('./assets/fonts/Gotham-Medium.otf') format('opentype');
}

/* include angular-material-typography($basic-typography);
  Font style end
Admin themes
*/

@import 'assets/loading-placeholder';
@import 'assets/table.scss-theme';
@import 'assets/datapicker.scss-theme';
@import 'assets/slide-toggle.scss-theme';
@import 'assets/dropdown-list.scss-theme';
@import 'assets/spinner.scss-theme';
@import 'assets/tooltip';
@import 'app/shared/components/check-list/check-list.scss-theme';
@import 'app/pages/admin/components/aside/aside.scss-theme';
@import '../projects/tokengear-common/src/pages/admin-dashboard/components/filter/filter.scss-theme';
@import '../projects/tokengear-common/src/pages/admin-dashboard/components/countries-statistic/countriea-statistic.scss-theme';
@import '../projects/tokengear-common/src/pages/admin-dashboard/components/tokens-statistic-item/token-statistic-item.scss-theme';
@import '../projects/tokengear-common/src/pages/admin-dashboard/components/tokens-statistic-chart/tokens-statistic-chart.scss-theme';
@import '../projects/tokengear-common/src/pages/admin-features/components/features-detail/features-accordion.scss-theme';

//Admin rtl themes
@import 'assets/input.scss-rtl';
@import 'assets/table.scss-rtl';
@import 'assets/slide-toogle.scss-rtl';
@import 'app/shared/components/check-list/check-list.scss-rtl';
@import 'app/pages/admin/components/aside/aside.scss-rtl';
@import 'app/pages/admin/components/header/header.scss-rtl';
@import '../projects/tokengear-common/src/pages/admin-dashboard/components/tokens-statistic-chart/token-statistic-chart.scss-rtl';
@import 'projects/tokengear-common/src/pages/admin-users/components/user-info/user-info.scss-rtl';
@import 'projects/tokengear-common/src/pages/admin-users/components/user-detail/user-detail.scss-rtl';
@import 'projects/tokengear-common/src/pages/admin-users/components/user-change-status/user-change-status.scss-rtl';
@import '../projects/tokengear-common/src/pages/admin-invoices/components/invoices-list/invoices-list.scss-rtl';
@import '../projects/tokengear-common/src/pages/admin-look-and-feel/components/settings/setting-look-and-feel.scss-rtl';
@import '../projects/tokengear-common/src/pages/admin-about/components/about-template-create/about-template-create.scss-rtl';
@import '../projects/tokengear-common/src/pages/admin-milestones/components/admin-milestones/admin-milestones.scss-rtl';
@import 'app/shared/components/badge/badge.scss-rtl';
@import '../projects/tokengear-common/src/pages/user-dashboard/components/tenant-coins-buy/tenant-coins-buy.scss-rtl.scss';
@import 'app/shared/components/sidenav/sidenav.scss-rtl.scss';

//User themes
@import 'app/pages/user/components/header/header.scss-theme';
@import 'app/pages/user/components/user/user.scss-theme';
@import '../projects/tokengear-common/src/pages/user-faq/components/faq/faq.scss-theme';
@import '../projects/tokengear-common/src/pages/user-contact/components/contact/contact.scss-theme';
@import 'app/pages/user/components/aside/aside.scss-theme';
@import 'assets/checkbox.scss-theme';
@import 'assets/radioBtn.scss-theme';
@import 'assets/select.scss-theme';

@import 'app/pages/user/components/user/user.scss-left-navigation';
@import 'app/pages/user/components/user/user.scss-right-navigation';
@import 'app/pages/user/components/user/user.scss-top-navigation';
@import 'app/pages/user/components/user/user.scss-mobile-navigation';

/*User rtl themes*/
@import 'assets/modal.scss-rtl';
@import 'app/pages/user/components/aside/aside.scss-rtl';
@import 'app/pages/user/components/toolbar/toolbar.scss-rtl';
@import 'app/pages/user/components/header/header.scss-rtl';
@import 'app/pages/user/components/user/user.scss-rtl';
@import 'app/pages/user/components/registration/registration.scss-rtl';
@import '../projects/tokengear-common/src/pages/user-dashboard/components/purchase-coins-by-plan/purchase-coins-by-plan.scss-rtl';
@import 'app/shared/components/profile/profile.scss-rtl';
@import '../projects/tokengear-common/src/pages/user-dashboard/components/transactions/transactions.scss-rtl';
@import '../projects/tokengear-common/src/pages/user-kyc/components/kyc-data-entry/kyc-data-entry.scss-rtl';
@import '../projects/tokengear-common/src/pages/user-dashboard/components/kyc-status-badge/kyc-status-badge.scss-rtl';
@import 'app/pages/user/components/kyc-notice/kyc-notice.component.rtl';
@import 'app/pages/user/components/user-info/user-info.scss-rtl';

@mixin admin-custom-theme() {
  @include table-theme;
  @include admin-aside-theme;
  @include filter-theme;
  @include spinner-theme;
  @include countriea-statistic-theme;
  @include token-statistic-item-theme;
  @include tokens-statistic-chart-theme;
  @include check-list-theme;
  @include slide-toggle-theme;
  @include features-accordion-theme;
  @include select-theme;
}

@mixin admin-custom-rtl() {
  @include input-rtl;
  @include table-rtl;
  @include header-rtl;
  @include aside-rtl;
  @include sidenav-rtl;
  @include check-list-rtl;
  @include token-statistic-chart-rtl;
  @include user-info-rtl;
  @include user-detail-rtl;
  @include user-change-status-rtl;
  @include slide-toogle-rtl;
  @include invoices-list-rtl;
  @include setting-look-and-feel-rtl;
  @include about-template-create-rtl;
  @include admin-milestones-rtl;
  @include badge-rtl;
}

@media (min-width: 960px) {
  @include top-navigation;
  @include left-navigation;
  @include right-navigation;
}

@media (max-width: 960px) {
  @include mobile-navigation;
}

@mixin user-custom-theme() {
  @include user-theme;
  @include header-theme;
  @include user-aside-theme;
  @include table-theme;
  @include faq-theme;
  @include contact-theme;
  @include spinner-theme;
  @include radioBtn-theme;
  @include slide-toggle-theme;
  @include select-theme;
}

@mixin user-custom-rtl() {
  @include user-rtl;
  @include user-aside-rtl;
  @include user-toolbar-rtl;
  @include user-header-rtl;
  @include purchase-coins-by-plan-rtl;
  @include profile-rtl;
  @include transactions-rtl;
  @include registration-rtl;
  @include modal-rtl;
  @include kyc-data-entry-rtl;
  @include badge-rtl;
  @include table-rtl;
  @include kyc-status-badge-rtl;
  @include kyc-notice-rtl;
  @include slide-toogle-rtl;
  @include user-info-rtl;
  @include input-rtl;
  @include tenant-coins-buy-rtl;
}

.admin-page {
  @include admin-custom-theme;
  &[dir='rtl'] {
    @include admin-custom-rtl;
  }
}

.user-page {
  background-color: #f8f9fa;
  @include user-custom-theme;
  &[dir='rtl'] {
    @include user-custom-rtl;
  }
}

.admin-page,
.user-page {
  &[dir='ltr'] {
    * {
      direction: ltr;
    }
  }
  &[dir='rtl'] {
    * {
      direction: rtl;
    }
  }
}

.user-page.color-style-dark {
  background-color: #141719;
  @import 'themes/dark-theme.scss';
  @include all-component-themes($custom-theme);
  color: white;
}

.user-page.color-style-light {
  color: black;
}

.disable-body-scroll {
  overflow: hidden;
}

/*Create component outside .admin-default-theme*/
@include dropdown-list-theme;
@include datapicker-theme;
@include checkbox-theme;
/*Create component outside .admin-default-theme end*/

@import '~@swimlane/ngx-dnd/release/index';

/*Style for What you see what you get plugin*/
.note-toolbar {
  position: static !important;
  width: 100% !important;
}
.texteditor-wrp {
  ul,
  ol {
    display: block;
    margin-top: 1em;
    margin-bottom: 1em;
    padding-left: 40px;
    li {
      padding: initial;
      margin: initial;
    }
  }
  ul {
    list-style-type: disc;
  }
  ol {
    list-style-type: decimal;
  }
}

// BG body  By tenant theme
.user-page.game-station {
  background-color: #110e23;
}

.user-page.kaishi {
  background-image: url(/assets/img/theme/kaishi/group-left-ellipse-expan-header.png),
    url(/assets/img/footer/footer-bg.png), linear-gradient(180deg, #1a2238 0%, #070d1d 100%);
  background-repeat: no-repeat, no-repeat;
  background-position: 0 0, 0 bottom;
  background-size: 50%, 100%, 100%;
  background-attachment: fixed;
}
