$font-size-base: 16px !default;

$font-size-xxxl: 36px;
$font-size-xxl: 24px;
$font-size-xl: 20px;
$font-size-l: 18px;
$font-size-m: $font-size-base;
$font-size-s: 14px;
$font-size-xs: 12px;
$font-size-xxs: 10px;

$font-sizes: (
  'xxl': $font-size-xxl,
  ø 'xl': $font-size-xl,
  'l': $font-size-l,
  'm': $font-size-m,
  's': $font-size-s,
  'xs': $font-size-xs,
  'xxs': $font-size-xxs,
);

$font-family-open-sans: 'Open Sans';
$font-family-gotham-medium: 'GothamMedium';
