@mixin input-rtl() {
  .input-text-item__item-text__text {
    left: 20px;
    padding-right: 20px;
    right: initial;
    padding-left: inherit;
  }
  .modal-form__item__item-icon__icon {
    left: 20px;
    right: initial;
    border-right: #e8eff4 solid 1px;
    padding-right: 20px;
    border-left: none;
    padding-left: 0;
  }

  .input-field {
    &__btn {
      right: auto;
      left: 7px;
    }
  }

  .filter {
    &__select {
      margin-left: 0;
    }
    &__label {
      margin-left: 0;
      margin-right: 15px;
    }
  }

  @media (max-width: 767px) {
    .filter {
      &__label {
        margin-right: initial;
      }
    }
  }
}
