@mixin admin-milestones-rtl() {
  .milestones-count {
    &__headline {
      margin-right: 0;
      margin-left: 30px;
    }
  }

  .dnd-block {
    &__btn {
      &:not(:last-child) {
        margin-right: 0;
        margin-left: 20px;
      }
    }

    &__icon {
      &_edit,
      &_delete {
        padding-right: 0;
        padding-left: 10px;
      }

      &_edit {
      }

      &_delete {
      }
    }
  }
}
