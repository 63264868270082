@mixin features-accordion-theme() {
  .mat-expansion-panel:not([class*='mat-elevation-z']) {
    box-shadow: none;
    border: 1px solid #e6e9eb;
    border-radius: 25px;
    overflow: hidden;
  }

  .mat-expansion-panel[_ngcontent-c18],
  .mat-accordion > .mat-expansion-panel-spacing:last-child,
  .mat-accordion > :last-child:not(.mat-expansion-panel) .mat-expansion-panel-spacing {
    margin-bottom: 17px;
  }

  .mat-expansion-panel-header {
    padding: 17px;
  }

  .mat-expansion-panel-body {
    padding: 17px;
  }

  .mat-expansion-indicator {
    margin-top: -5px;
  }

  [aria-expanded='true'] {
    .mat-expansion-indicator {
      margin-top: 0;
    }
  }
}
