@mixin table-rtl() {
  .mat-header-cell,
  .mat-cell {
    &:first-child {
      text-align: right;
      padding-left: 0;
      padding-right: 24px;
    }
    &:last-child {
      text-align: left;
      padding-right: 0;
      padding-left: 24px;
    }
    &.pending-block {
      padding-right: 24px;
    }
  }

  .mat-header-cell,
  .mat-cell {
    &:not(:first-child) {
      & .mat-sort-header-container {
        & .mat-sort-header-button {
          margin-left: 0;
          margin-right: 20px;
        }
      }
    }
  }

  .name-block {
    &__avatar {
      margin-right: 0;
      margin-left: 15px;
    }
  }

  .role-block {
    &__link {
      transform: rotate(180deg);
    }
  }

  .table-filter {
    .filter {
      &__select {
        margin-left: 15px;
        margin-right: 0;
      }
    }
  }

  .pending-bg,
  .approved-bg,
  .declined-bg {
    td:last-of-type {
      &:after {
        right: auto;
        left: 0;
      }
    }
  }

  .pending-bg {
    td:last-of-type {
      box-shadow: 3px 0px 0px #f5a623 inset;
    }
  }

  .approved-bg {
    td:last-of-type {
      box-shadow: 3px 0px 0px #23b000 inset;
    }
  }

  .declined-bg {
    td:last-of-type {
      box-shadow: 3px 0px 0px #ff2800 inset;
    }
  }

  .table-info {
    &_full {
      text-align: right;
    }
  }

  @media (max-width: 767px) {
    .mobile-hidden-text {
      padding-right: 0;
      padding-left: 5px;
    }
    .mobile-label {
      padding-right: 0;
      padding-left: 5px;
    }

    .grid {
      &-1-1 {
        grid-row: 1;
        grid-column: 1;
      }
      &-2-1 {
        grid-row: 2;
        grid-column: 1;
      }
      &-3-1 {
        grid-row: 3;
        grid-column: 1;
      }
      &-1-2 {
        grid-row: 1;
        grid-column: 2;
      }
      &-2-2 {
        grid-row: 2;
        grid-column: 2;
      }
      &-3-2 {
        grid-row: 3;
        grid-column: 2;
      }
    }
  }
}
