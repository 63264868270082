@use '~@angular/material' as mat;
@import '~@angular/material/theming';
$admin-primary: mat.define-palette(mat.$deep-purple-palette, 500, 300, 900);
$admin-accent: mat.define-palette(mat.$light-blue-palette);
$admin-warn: mat.define-palette(mat.$pink-palette, 600);

$admin-theme: mat.define-light-theme($admin-primary, $admin-accent, $admin-warn);

$user-primary: mat.define-palette(mat.$blue-palette, 500, 300, 900);
$user-accent: mat.define-palette(mat.$light-blue-palette);
$user-warn: mat.define-palette(mat.$pink-palette, 600);

$user-theme: mat.define-light-theme($user-primary, $user-accent, $user-warn);

$test-color: (
  primary: #7460ee,
);

$progress-bar: (
  primary: #000,
  secondary: #fff,
);

.kyc-notice {
  background-color: #f8f9fa;
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: #fff !important;
}

mat-expansion-panel {
  background: transparent !important;
}
