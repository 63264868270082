@mixin user-toolbar-rtl {
  .ico-logo {
    &__img {
      margin-right: 0;
      margin-left: 10px;
    }
  }

  @media (max-width: 960px) {
    .mobile-view-wrp {
      &_inner {
        margin-left: 0;
        margin-right: 10px;
      }
    }
  }
}
