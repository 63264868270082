@mixin table-theme() {
  .table-wrap {
    overflow: auto;
  }

  table {
    width: 100%;
    box-shadow: none;
  }

  .mat-header-row,
  .mat-row {
    height: 60px;
  }

  .mat-header-row {
    &_dashboard {
      height: 40px;
      background-color: initial;
    }
  }

  .mat-row {
    &:nth-child(even) {
      background-color: #f8f9fa;
    }
    &_dashboard {
      font-size: 1.8rem;
      &:nth-child(even) {
        background-color: initial;
      }
      &:last-child {
        .mat-cell {
          border-bottom: 0;
        }
      }
    }
  }

  .mat-header-cell,
  .mat-cell {
    border-bottom: 1px solid #edf2f7;
    &:not(:first-child) {
      text-align: center;
      & .mat-sort-header-container {
        justify-content: center;
        & .mat-sort-header-button {
          margin-left: 12px;
        }
      }
    }
    &:last-child {
      text-align: right;
    }
    .mat-sort-header-container {
      & .mat-sort-header-button:disabled {
        cursor: default !important;
      }
    }
  }

  .mat-header-cell {
    font-size: 1.4rem;
    font-weight: 400;
    color: #8796a6;
  }

  .mat-cell {
    font-size: 1.3rem;
  }

  .mat-paginator {
    font-size: 1.4rem;
    font-weight: 400;
    color: #8796a6;
  }

  .mat-primary .mat-option.mat-selected:not(.mat-option-disabled),
  .mat-form-field.mat-focused.mat-primary .mat-select-arrow {
    color: inherit;
  }

  .mat-paginator {
    & .mat-paginator-container {
      justify-content: space-between;
      padding: 0;
    }

    & .mat-paginator-page-size-select {
      margin: 0;
    }

    & .mat-paginator-page-size-label,
    & .mat-form-field-underline {
      display: none;
    }

    & .mat-paginator-page-size,
    & .mat-form-field {
      max-width: 215px;
      width: 100%;
    }

    & .mat-select-trigger {
      height: 40px;
      line-height: 40px;
      border: 1px solid #e8eff4;
      border-radius: 5px;
      padding: 0 17px;
      margin-bottom: 20px;
      transition: all 0.25s;
      &:hover,
      &:focus {
        border-color: #7460ee;
      }
    }

    .mat-paginator-range-label {
      color: initial;
    }

    & .mat-form-field-wrapper {
      padding-bottom: 0 !important;
    }

    & .mat-form-field-infix {
      padding: 0 !important;
    }
  }

  .mat-sort-header-sorted {
    color: initial;
    .mat-sort-header-arrow {
      color: initial;
    }
  }

  .name-block {
    display: flex;
    align-items: center;
    min-width: 160px;
    width: 100%;
    word-break: break-word;
    cursor: pointer;
    padding: 10px 0;
    &__avatar {
      min-width: 34px;
      width: 34px;
      height: 34px;
      border-radius: 50px;
      vertical-align: middle;
      margin-right: 15px;
      &.icon {
        background-color: #8796a6;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #fff;
        font-size: 18px;
      }
    }
    &__name {
      transition: 0.25s;
      word-break: break-all;
    }
    &__description {
      color: #a3a2a2;
      font-size: 12px;
      margin-top: 5px;
    }
    &__id {
      display: block;
    }
    &:hover {
      & .name-block {
        &__name {
          color: #7460ee;
        }
      }
    }
  }

  .role-block {
    &__name {
      display: inline-block;
      width: 80px;
    }
    &__link {
      line-height: 40px;
      background-color: #edf2f7;
      box-shadow: none !important;
    }
  }

  .wallet-block {
    position: relative;
    max-width: 115px;
  }

  .references-block {
    max-width: 290px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .hash-block-transactions {
    max-width: 100px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    &__link {
      color: #0072ff;
    }
  }

  .hash-block {
    &__link {
      line-height: 40px;
      background-color: #0072ff;
      box-shadow: none !important;
    }
    &__icon {
      font-size: 2rem;
    }
  }

  .pending-block {
    padding: 0 24px;
    &__btn {
      line-height: 40px;
      box-shadow: none !important;
      &_confirm {
        background-color: #23b000;
      }
      &_decline {
        background-color: #ff2800;
      }
    }
  }

  .mat-row {
    &.pending-bg {
      background-color: rgba(245, 166, 35, 0.05);
      td:last-of-type {
        box-shadow: -3px 0px 0px #f5a623 inset;
      }
    }
    &.approved-bg {
      background-color: rgba(35, 176, 0, 0.05);
      td:last-of-type {
        box-shadow: -3px 0px 0px #23b000 inset;
      }
    }
    &.declined-bg {
      background-color: rgba(255, 40, 0, 0.05);
      td:last-of-type {
        box-shadow: -3px 0px 0px #ff2800 inset;
      }
    }
  }

  @media (max-width: 767px) {
    .mat-mini-fab .mat-button-wrapper {
      padding: 0;
    }

    table {
      min-width: 767px;
    }

    .mat-header-row,
    .mat-row {
      height: 45px;
    }

    .mat-paginator {
      & .mat-paginator-container {
        flex-wrap: wrap;
        margin-top: 10px;
      }

      .mat-select-trigger {
        margin-bottom: 0;
      }

      .mat-form-field-infix {
        border-top: 0;
      }

      & .mat-paginator-page-size,
      & .mat-form-field {
        max-width: 80px;
      }

      & .mat-paginator-page-size {
        margin-right: 0;
      }

      & .mat-paginator-range-label {
        margin: 0 10px 0 0;
      }
    }

    .role-block,
    .hash-block {
      &__icon {
        font-size: 1.6rem;
      }
      &__link {
        width: 30px;
        height: 30px;
        line-height: 28px;
      }
    }
    .pending-block {
      &__btn {
        width: 30px;
        height: 30px;
        line-height: 30px;
      }
      &__icon {
        font-size: 1.8rem;
      }
    }
  }
}
