@mixin filter-theme() {
  .mat-button-toggle-label-content {
    line-height: 38px !important;
  }

  .mat-button-toggle-group {
    position: static;
    display: flex;
    flex-direction: row;
    align-items: self-start;
    flex-wrap: wrap;
    box-shadow: none;
    border-radius: 0;
    cursor: pointer;
    white-space: normal;
    overflow: hidden;
    border: none;
  }

  .mat-button-toggle {
    color: initial;
    border-width: 2px;
    border-style: solid;
    border-color: #e8eff4;
    background-color: #e8eff4;
    transition: all 0.25s;
    &:hover {
      border-color: #7460ee;
      background-color: #fff;
    }
    &-checked {
      color: #fff;
      border-color: #7460ee;
      background-color: #7460ee;
      &:hover {
        border-color: #7460ee;
        background-color: #7460ee;
      }
    }
  }
  @media (max-width: 992px) {
    .mat-button-toggle-group {
      width: 100%;
      justify-content: start;
    }
  }
}
