@mixin radioBtn-theme() {
  .mat-radio-outer-circle {
    background-color: #ffffff;
    border: 1px solid #8796a6;
  }
  .mat-radio-label-content {
    padding-left: 0;
  }

  .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
    border-color: #8796a6 !important;
  }

  .mat-radio-container {
    margin-left: 5px;
    margin-right: 5px;
  }

  .mat-radio-inner-circle {
    border-radius: 50%;
    box-sizing: border-box;
    height: 26px;
    left: -3px;
    position: absolute;
    top: -3px;
    transition: transform ease 280ms, background-color ease 280ms;
    width: 26px;
  }
}
