@mixin purchase-coins-by-plan-rtl {
  .information-block {
    text-align: right;
    padding-right: 21px;
  }
  .app-buy-tokens-multi-transaction-information {
    text-align: right;
  }

  @media (max-width: 1199px) {
    .app-buy-tokens-multi-transaction-information {
      text-align: center;
    }

    .information-block .points-block__item {
      text-align: right;
    }
  }
}
