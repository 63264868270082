@mixin about-template-create-rtl() {
  .row {
    & > div {
      &:last-child {
        padding-left: 0;
        padding-right: 10px;
      }
    }
  }

  .create-own-template {
    &__title {
      &_pr {
        padding-right: 0;
        padding-left: 10px;
      }
    }
  }
}
