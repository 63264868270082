@mixin user-header-rtl {
  app-header {
    .filter__select {
      border-right: 1px solid #e8eff4;
      border-left: 0;
    }

    .button-block__button.mat-stroked-button {
      margin-left: 15px;
      margin-right: 0;
    }

    .button-block__button {
      margin-left: 20px;
      margin-right: 0;
    }
  }
}
