@mixin registration-rtl() {
  .registration-btn {
    margin-right: 0;
    margin-left: 20px;
  }

  .sign-in-btn {
    margin-right: 0;
    margin-left: 12px;
  }
}
