@mixin admin-aside-theme() {
  .nav-list {
    .mat-button-wrapper {
      width: 100%;
      display: flex;
      align-items: center;
      padding: 0;
    }
  }

  .mat-button-focus-overlay {
    background-color: transparent !important;
  }
}
