@mixin faq-theme {
  app-faq {
    .mat-card-header {
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 48px 15px;
    }

    .mat-card-content {
      padding: 56px 0;
    }

    .mat-expansion-panel {
      max-width: 640px;
      width: 100%;
      margin: 0 auto 28px;
      box-shadow: none;
      border: 1px solid #e8eff4;
    }

    .mat-expansion-panel:not([class*='mat-elevation-z']) {
      box-shadow: none;
    }

    .mat-expansion-indicator::after {
      border-color: #8796a6;
      color: transparent;
    }

    .mat-accordion > .mat-expansion-panel-spacing:last-child {
      margin-bottom: 28px;
    }

    @media (max-width: 1024px) {
      .mat-card-content {
        padding: 56px;
      }
    }

    @media (max-width: 960px) {
      .mat-card-content {
        padding: 56px 35px;
      }
    }

    @media (max-width: 600px) {
      .mat-card-content {
        padding: 56px 15px;
      }
    }
  }
}

@media (hover: none) {
  .mat-expansion-panel:not(.mat-expanded):not([aria-disabled='true']) .mat-expansion-panel-header:hover {
    background-color: rgba(0, 0, 0, 0.04) !important;
  }
}

app-faq {
  .game-station {
    mat-card {
      background: #17142f;
      border-radius: 10px;
      color: #ffffff;
    }
    .form-wrapper__header {
      font-family: 'Open Sans';
      font-size: 14px;
    }
    .block-line {
      display: none;
    }

    .mat-expansion-panel {
      max-width: 640px;
      width: 100%;
      margin: 0 auto 28px;
      box-shadow: none;
      border: 1px solid #e6298b !important;
      border-radius: 10px;
    }

    .mat-expansion-panel-header.mat-expanded {
      box-shadow: inset 0px -1px 0px #e5298b;
    }

    .mat-expansion-indicator::after {
      border-color: #ffffff !important;
      color: transparent;
    }
    .mat-expansion-panel-header-title,
    .mat-expansion-panel-body {
      color: #ffffff;
    }

    .mat-accordion > .mat-expansion-panel-spacing:last-child {
      margin-bottom: 28px;
    }
  }
}
