@mixin token-statistic-chart-rtl() {
  .tokens-stat-composition-chart {
    .advanced-pie,
    .advanced-pie-legend {
      display: flex;
      flex-direction: row-reverse;
      float: right;
    }
    .advanced-pie-legend .legend-items-container .legend-items .legend-item .item-color {
      margin-right: 0;
      margin-left: 7px;
    }
  }

  .chart-list {
    &__item {
      margin-left: 0;
      &:before {
        margin-right: 0;
        margin-left: 5px;
      }
      &:not(:first-child) {
        margin-left: 0;
        margin-right: 10px;
      }
    }
  }
}
