@mixin sidenav-rtl {
  .header__btn {
    right: 0;
  }

  .header__left-part {
    margin-right: 20px;
  }

  .nav-list__link {
    text-align: right;
  }
}
