@mixin select-theme() {
  .mat-select-trigger .mat-select-arrow-wrapper .mat-select-arrow {
    width: 12px;
    height: 8px;
    border: none !important;
    position: relative;
    right: -8px;
    transform: revert;
    &:before,
    &:after {
      content: '';
      display: inline-block;
      width: 2px;
      height: 9px;
      background-color: #8796a6;
      position: absolute;
      top: -2px;
    }
    &:before {
      transform: rotate(45deg);
      right: 5px;
    }
    &:after {
      transform: rotate(-45deg);
      left: 0;
    }
  }
  .disabled-select .mat-select-trigger .mat-select-arrow-wrapper .mat-select-arrow {
    width: 12px;
    height: 8px;
    border: none !important;
    position: relative;
    right: -8px;
    &:before,
    &:after {
      content: none;
      display: inline-block;
      width: 2px;
      height: 9px;
      background-color: #8796a6;
      position: absolute;
      top: -2px;
    }
  }

  .mat-select-value {
    letter-spacing: -0.31px;
  }
}
