@mixin setting-look-and-feel-rtl() {
  .look-inner {
    &_column {
      &_margin {
        margin-left: 0;
        margin-right: 50px;
      }
    }
  }

  .img-block {
    margin-right: 0;
    margin-left: 50px;
    position: relative;
  }

  .look-block {
    &:not(:last-child) {
      margin-right: 0;
      margin-left: 30px;
    }
    &_list {
      margin-left: 0;
      margin-right: 30px;
    }
  }

  .color-picker {
    &__bg {
      margin-right: 0;
      margin-left: 15px;
    }
  }

  .color-block {
    margin-left: 0;
    margin-right: 50px;
  }
  .gradient-block {
    margin-left: 0;
    margin-right: 50px;
  }

  @media (max-width: 1300px) {
    .look-block {
      &_list {
        margin-left: 0;
        margin-right: 10px;
      }
    }

    .gradient-block {
      margin-left: 0;
      margin-right: 45px;
    }
  }

  @media (max-width: 1199px) {
    .img-block {
      margin-right: 0;
      margin-left: 30px;
    }
  }

  @media (max-width: 890px) {
    .look-block {
      &_list {
        margin-left: 0;
        margin-right: 0;
      }
    }

    .gradient-block {
      margin-left: 0;
      margin-right: 0;
    }
  }

  @media (max-width: 790px) {
    .gradient-block {
      margin-left: 0;
      margin-right: 0;
    }
  }

  @media (max-width: 767px) {
    .look-inner {
      &_column {
        margin-left: 0;
        margin-right: 0;
      }
    }
  }
}
