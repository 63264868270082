@mixin header-theme() {
  app-header {
    .mat-select-arrow {
      color: #000000;
      border-left: 4px solid transparent;
      border-right: 4px solid transparent;
      border-top: 4px solid;
      margin: 0 5px;
    }

    .mat-button-focus-overlay {
      background-color: rgba(0, 0, 0, 0);
    }

    .mat-form-field-infix {
      display: inline-block;
      width: auto;
    }
  }
}

.game-station {
  .header-wrapper {
    display: flex;
    align-items: center;
    height: 68px;
  }
}

.kingaru,
.trial {
  .header-wrapper {
    display: flex;
    align-items: center;
    height: 68px;
  }
}
.kaishi {
  .header-wrapper {
    background: transparent;
    display: flex;
    align-items: center;
    //height: 90px;
    //.switch {
    //  display: block!important;
    //}
  }
}

@media (max-width: 960px) {
  .kaishi {
    .header-wrapper {
      .switch {
        display: none !important;
      }
    }
  }
}

@media (max-width: 500px) {
  .game-station {
  }
}
@media (max-width: 500px) {
  .game-station {
  }
}
