@mixin user-aside-rtl {
  app-aside {
    .mat-nav-list a {
      text-align: right;
    }

    .mat-button-wrapper {
      align-items: center;
      display: flex;
      flex-direction: row;

      .nav-icons {
        margin-right: 0;
        margin-left: 10px;
      }
    }
  }
}
