@mixin mobile-navigation {
  .menu-position-left_side_bar:not(.admin-page),
  .menu-position-right_side_bar:not(.admin-page),
  .menu-position-horizontal:not(.admin-page) {
    .main-wrap__aside {
      display: block;
    }

    .mat-drawer {
      bottom: auto;
      transform: translate3d(0, -100%, 0);
      padding: 0;
    }

    .header {
      &__left-part {
        justify-content: space-between;
      }
      &__btn {
        margin: 0 !important;
      }
    }

    .mat-drawer-content {
      position: static;
      margin: 0 !important;
      overflow: auto !important;
    }
  }
}
