.game-station {
  .filter {
    display: flex;
    &__input,
    &__select,
    &__textarea {
      width: 100%;
      height: 48px;
      box-shadow: none;
      padding: 0 17px;
      transition: 0.25s;
      background: #110e23;
      color: #ffffff;
      border-radius: 10px;
      font-weight: normal;
      border: none;
      font-size: 16px;
      &:focus {
        border: 1px solid #18d5dd;
        outline: none;
      }
    }
    &__input {
      max-width: 100%;
      &::placeholder {
        color: #878690;
      }
    }
    &__select {
      min-width: 215px;
      line-height: 48px;
    }
    &__select-mini {
      min-width: 135px;
    }
    &__label {
      font-size: 16px;
      color: #ffffff;
      padding-top: 14px;
      margin-left: 15px;
      margin-right: 15px;
    }
  }

  .mat-select-value-text {
    color: inherit;
  }
  .mat-select-value {
    color: inherit;
  }
}

.kaishi {
  .filter--error {
    background: linear-gradient(110.05deg, #fd7575 13.06%, rgba(253, 117, 117, 0.5) 84.95%);
    color: white !important;
  }
  .kyc__select {
    &.mat-select-panel {
      background: transparent;

      .mat-option {
        height: 53px !important;
        background-color: #242f4e !important;
        color: white !important;
        border-bottom: 1px solid #181f35;

        span {
          color: white;
        }
      }
    }
  }
}
