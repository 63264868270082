@mixin tokens-statistic-chart-theme() {
  .tokens-stat-composition-chart {
    & > * {
      width: inherit !important;
      padding-right: 20px;
    }

    .advanced-pie-legend-wrapper {
      width: calc(100% - 193px) !important;
      overflow: auto;
      display: flex;
      flex-direction: column;
      justify-content: center;
      .advanced-pie-legend {
        transform: none;
        width: inherit !important;
        top: -35px;
      }
    }

    .advanced-pie-legend .legend-items-container .legend-items {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      max-height: 160px;
      overflow: visible;
    }

    .advanced-pie-legend .legend-items-container .legend-items .legend-item {
      display: flex !important;
      font-size: 1.4rem !important;
      &:not(:last-child) {
        margin-bottom: 15px;
      }
    }

    .advanced-pie-legend .legend-items-container .legend-items .legend-item .item-percent {
      font-size: 1.4rem !important;
      color: #8796a6;
    }

    .advanced-pie-legend .total-value,
    .advanced-pie-legend .total-label,
    .advanced-pie-legend .legend-items-container .legend-items .legend-item .item-value {
      display: none;
    }

    .advanced-pie-legend .legend-items-container .legend-items .legend-item .item-label {
      margin-top: 0 !important;
      opacity: 1 !important;
    }

    .advanced-pie-legend .legend-items-container .legend-items .legend-item .item-color {
      width: 10px !important;
      height: 10px !important;
      border-radius: 50px;
      border-left: 10px solid;
    }
  }

  .token-stat-chart {
    text {
      font-size: 1.3rem !important;
      opacity: 0.5;
    }

    .chart-legend .legend-labels {
      background-color: transparent !important;
    }

    .chart-legend .legend-label-color {
      height: 8px;
      width: 8px;
      border-radius: 50px;
    }

    .chart-legend .legend-label-text {
      line-height: 12px;
    }
  }

  .tick * {
    direction: ltr !important;
  }

  @media (max-width: 767px) {
    .tokens-stat-composition-chart {
      height: auto;

      .advanced-pie-legend-wrapper {
        width: 100% !important;
      }
      & > div {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100% !important;
        height: auto !important;
        padding-right: 0;
      }
      .advanced-pie-legend .legend-items-container .legend-items {
        flex-direction: row;
        max-height: auto;
        padding: 0 15px;
      }
      .legend-item {
        margin-bottom: 17px;
      }
    }
    .advanced-pie {
      height: auto !important;
      float: none;
    }
    .advanced-pie-legend,
    .advanced-pie-legend-wrapper {
      width: 100% !important;
    }
    .advanced-pie-legend {
      position: static;
      float: none;
      transform: none;
    }
    .advanced-pie-legend-wrapper {
      height: auto !important;
    }
  }
}
