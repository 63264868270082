@mixin user-aside-theme() {
  app-aside {
    .mat-select-value {
      font-size: 16px;
    }

    .filter__select:focus,
    .filter__textarea:focus {
      border-color: #e8eff4;
    }
  }

  .mat-button-focus-overlay {
    background-color: transparent !important;
  }
}

.kingaru {
  .sidebar {
    .nav-list {
      padding: 0;

      &__link {
        width: 100%;
        height: 40px;
        font-size: 1.6rem;
        font-weight: 400;
        line-height: normal;
        text-align: left;
        padding: 10px 16px;
        margin-bottom: 8px;

        //&:last-child {
        //  padding-top: 30px;
        //  margin-top: 20px;
        //  border-top: solid 1px #e8eff4;
        //}

        .nav-icons {
          color: #ffffff;
          min-width: 20px;
          width: 20px;
          height: 20px;
        }
        & > * {
          display: flex;
        }
        &_lang {
          display: none;
          padding: 0;
        }
        &_mobile {
          display: none;
        }
      }
    }
  }
}

.kaishi {
  .doted {
    width: 80%;
    margin: auto;
    margin-bottom: 11px;
    height: 3px;
    background-image: linear-gradient(to right, #e8eff499 33%, rgba(255, 255, 255, 0) 0%);
    background-size: 7px 1px;
    background-repeat: repeat-x;
    display: block;
  }
  .doted-line-bottom {
    margin-top: 18px;
  }
  .sidebar {
    padding: 0 10px 0 10px;
    .nav-list {
      display: flex;
      flex-direction: column;
      justify-content: center;
      &__link {
        width: 100%;
        height: 40px;
        font-size: 16px;
        line-height: 20px;
        font-weight: 300;
        min-width: auto;
        text-align: left;
        padding: 10px 16px;
        margin-bottom: 7px;
        color: #ffffff;
        padding-left: 22px;

        &__active {
          font-weight: bold !important;
        }
        & > * {
          display: flex;
        }

        .nav-icons {
          color: #ffffff;
          min-width: 20px;
          width: 20px;
          height: 20px;
        }
        &_mobile {
          display: none;
        }
        svg-icon svg path {
          fill: #ffffff !important;
          stroke: none;
        }
        svg-icon svg rect {
          fill: #ffffff !important;
          stroke: none;
        }
        span {
          font-family: 'Source Sans Pro', sans-serif;
        }
        &__active {
          color: #3bdcb1 !important;
          svg-icon svg path {
            fill: #3bdcb1 !important;
            stroke: none;
          }
          svg-icon svg rect {
            fill: #3bdcb1 !important;
            stroke: none;
          }
        }
      }
    }

    .privacy-box {
      display: none;
    }
  }
  .social-icons {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    margin-top: 20px;
    margin-bottom: 65px;
    img {
      width: 22px;
    }
    &__item {
      cursor: pointer;
    }
  }
}

.game-station {
  .sidebar {
    .nav-list {
      padding: 14px;
      &__link {
        font-family: GothamLight;
        font-size: 18px;
        font-weight: 400;
        height: 51px;
        width: 100%;
        display: flex;
        align-items: center;
        color: #ffffff;
        .nav-icons {
          color: #ffffff;
          min-width: 20px;
          width: 20px;
          height: 20px;
        }
        &__active {
          color: #e7298b !important;
        }
      }
    }
  }
  .chain-section__item__links {
    color: white;
  }
}

.trial {
  .sidebar {
    .nav-list {
      &__link {
        font-size: 18px;
        font-weight: 400;
        height: 51px;
        width: 100%;
        display: flex;
        align-items: center;
        color: #0b2c37 !important;
        .nav-icons {
          color: #ffffff;
          min-width: 20px;
          width: 20px;
          height: 20px;
        }
        &__active {
          background-color: #18586e;
          border-radius: 5px;
          color: white !important;
          svg-icon svg path {
            fill: white !important;
            stroke: none;
          }
        }
      }
    }
  }
  .mat-content {
    .nav-icons.chain-links-icon svg path {
      fill: #18586e !important;
    }

    .chain-links-text {
      color: #0b2c37;
    }
  }
  .nav-list__link {
    font-size: 18px;
  }
}

@media (max-width: 960px) {
  .kaishi {
    .tenant-logo {
      display: flex;
      margin-bottom: 30px;
      &__img {
        height: 50px;
      }
      &__text {
        color: #ffffff;
        line-height: 50px;
        font-size: 50px;
        text-transform: lowercase;
        font-weight: 500;
        font-family: Rubik;
        margin-left: 10px;
        text-align: left;
        white-space: nowrap;
      }
    }
    .sidebar {
      padding: 0 10px 0 10px;
      text-align: center;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 100%;
      z-index: 0;
      .nav-list {
        &__link {
          font-size: 18px;
          line-height: 23px;
          align-items: center;
          svg-icon svg {
            width: 30px;
            height: 30px;
          }
          &__active {
            font-weight: normal !important;
            svg-icon svg {
              width: 30px;
              height: 30px;
            }
          }
        }
      }
      .close-menu {
        color: #fff;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        &__icon-arrow {
          transform: rotate(180deg);
        }
        &__button {
          width: 255px;
          height: 45px;
          background: linear-gradient(180deg, #22b9b5 0%, #37d6b2 100%);
          box-shadow: 0 0 0 2px #1a2238, -5px -5px 10px rgba(255, 255, 255, 0.1), 5px 10px 10px rgba(1, 7, 19, 0.2);
          border-radius: 30px;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
        }
        &__text {
          color: #fff;
          font-size: 16px;
          font-family: Rubik, sans-serif;
        }
      }
    }

    .nav-icons,
    .info-block,
    .block-line {
      display: flex;
    }

    .doted-line-top {
      max-width: 100%;
      margin-bottom: 19px;
      margin-right: 0;
      margin-left: 0;
    }

    .doted-line-bottom {
      margin: 20px 0 0 0;
      max-width: 100%;
    }
    .social-icons {
      &__item {
        height: 45px;
        width: 45px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 10px;
        background: linear-gradient(180deg, #35405b 0%, #222c45 100%);
        box-shadow: 0 0 0 2px #1a2238, -5px -5px 10px rgb(255 255 255 / 10%), 5px 10px 10px rgb(1 7 19 / 20%);
        border-radius: 30px;
      }
    }
    .privacy-box {
      display: none;
      justify-content: center;
      flex-direction: column;
      position: absolute;
      bottom: 25px;
      a {
        font-size: 14px;
        line-height: 18px;
        text-align: center;
        text-decoration-line: underline;
        color: #ffffff;
      }
    }
  }
}

@media (max-height: 650px) {
  .kaishi {
    .social-icons {
      margin-bottom: 0;
    }
  }
}
