@mixin checkbox-theme() {
  .mat-checkbox-frame {
    border-width: 1px !important;
    border-color: #8796a6;
    background-color: #fff !important;
  }

  .mat-checkbox-layout {
    white-space: normal !important;
  }

  .mat-checkbox-layout .mat-checkbox-label {
    line-height: normal;
  }

  .mat-radio-button .mat-radio-inner-circle {
    background-color: #0072ff;
  }

  .game-station {
    .mat-checkbox-layout {
      align-items: center !important;
    }

    .mat-checkbox-layout .mat-checkbox-label {
      font-family: Open Sans;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      margin-left: 5px;
    }
    .mat-checkbox-frame {
      border-radius: 4px !important;
      border: 1px solid rgba(135, 134, 144, 0.25) !important;
      background-color: #17142f !important;
    }
  }
}
