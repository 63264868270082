@mixin top-navigation {
  .menu-position-horizontal:not(.admin-page) {
    .top-navigation-wrapper {
      display: block;
    }

    .mat-drawer-content {
      margin: 0 !important;
    }

    .main-wrap__aside {
      display: none !important;
    }
  }
}
