@mixin contact-theme() {
  app-contact {
    .header-first,
    .header-second {
      font-size: 24px;
    }
    .mat-card-header {
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 48px 15px;
    }
    .mat-form-field {
      display: block;
      position: relative;
      text-align: left;
    }
  }
}

app-contact {
  .game-station {
    mat-card {
      background: #17142f;
      border-radius: 10px;
      color: #ffffff;
    }

    .form-wrapper__header {
      font-family: 'Open Sans';
      font-size: 14px;
    }

    .file-select__status {
      background-color: #110e23 !important;
      height: 48px !important;
      line-height: 48px !important;
      border-radius: 10px;
      border: none;
      font-weight: normal;
      font-size: 14px;
      color: #878690;
    }

    .block-line {
      display: none;
    }

    .form-wrapper {
      button {
        background: #18d5dd;
        height: 48px !important;
        border-radius: 10px !important;
        font-family: GothamMedium;

        &:hover {
          color: #10004d !important;
          cursor: pointer;
        }
      }
    }

    .setting-btn {
      min-width: 160px;
    }
  }
}

app-contact {
  .game-station {
    mat-card {
      background: #17142f;
      border-radius: 10px;
      color: #ffffff;
    }

    .file-select__status {
      background-color: #110e23 !important;
      border-radius: 10px;
      border: none;
      font-weight: normal;
      font-size: 14px;
      color: #878690;
    }

    .block-line {
      display: none;
    }

    button {
      background: #18d5dd;
      border-radius: 10px !important;
      font-family: GothamMedium;

      &:hover {
        color: #10004d !important;
        cursor: pointer;
      }
    }

    .setting-btn {
      min-width: 160px;
    }
  }
}
