@mixin transactions-rtl {
  .mat-column-transactionId,
  .mat-column-referenceId {
    text-align: right;
    padding-left: 0;
    padding-right: 24px;
  }

  .mat-header-cell:not(:first-child) .mat-sort-header-container .mat-sort-header-button,
  .mat-cell:not(:first-child) .mat-sort-header-container .mat-sort-header-button {
    margin-left: 0;
    margin-right: 12px;
  }
}
