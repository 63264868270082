@mixin check-list-rtl() {
  .check-list {
    &__item {
      padding-left: 0;
      padding-right: 35px;
    }
    &__icon {
      right: 0;
    }
  }
}
