.kaishi {
  .mat-drawer-container {
    box-shadow: 0 4px 20px rgb(57 71 108 / 50%);
    border-radius: 20px;
    margin: 100px 20px 0 20px;
    background: #182034;
  }

  .mat-drawer-side {
    border-right: solid 1px rgb(30 38 63);
  }
  .page-card {
    position: relative;
    height: 100%;
    overflow: hidden;
  }
  .spinner-bg {
    background-color: #181f35;
  }

  ::-webkit-scrollbar {
    width: 7px;
    height: 7px;
    overflow-y: scroll;
    background: grey;
    box-shadow: inset 0 0 4px #707070;
  }

  ::-webkit-scrollbar-thumb {
    background: #1d253f;
    border-radius: 10px;
  }
}

.game-station {
  .spinner-bg {
    background-color: #110e23;
  }

  ::-webkit-scrollbar {
    width: 4px;
    overflow-y: scroll;
    background: grey;
    box-shadow: inset 0 0 4px #707070;
  }

  ::-webkit-scrollbar-thumb {
    background: #17142f;
    border-radius: 10px;
  }
}

@media (max-width: 960px) {
  .kaishi {
    .mat-drawer-container {
      margin: 0;
      box-shadow: none;
      background: transparent;
    }
    .mat-drawer-inner-container {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .spinner-bg {
      background: transparent;
    }
  }
}
