@mixin check-list-theme() {
  .mat-checkbox-frame {
    border-width: 1px;
    border-color: #e8eff4;
  }

  .mat-checkbox-checkmark-path {
    stroke: #000 !important;
  }
}
