@mixin right-navigation {
  .menu-position-right_side_bar:not(.admin-page) {
    .side-navigation-wrapper {
      display: block;
    }

    .mat-sidenav-container {
      display: flex;
      flex-direction: row-reverse;
      .main-wrap__aside {
        transform: initial !important;
      }
    }

    .mat-drawer-content {
      width: 100%;
    }

    &[dir='ltr'] {
      .mat-drawer-content {
        margin-left: 0 !important;
        margin-right: 230px !important;
      }
    }

    &[dir='rtl'] {
      .mat-drawer-content {
        margin-left: 230px !important;
      }
    }
  }
}
